<template>
  <SimpleTableForm
    :id="id"
    title="Create IrrigationType"
    :table-config="tableConfig"
    :return-route="adminRoute"
    :server-type="serverType"
    @created="$emit('created')"
  />
</template>

<script>

import { irrigationTypeForm } from './constants'
import SimpleTableForm from '@/components/form/SimpleTableForm'

export default {
  name: 'IrrigationTypeForm',
  namespaced: true,
  components: { SimpleTableForm },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      tableConfig: irrigationTypeForm,
      serverType: 'irrigation_types',
      adminRoute: '/admin/irrigationTypes'
    }
  }
}
</script>
