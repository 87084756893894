export const irrigationTypeFields = [
  {
    name: 'lowWaterFactor',
    label: 'Low Water Factor',
    suffix: 'kgCO2e/kg',
    prefix: 'it'
  },
  {
    name: 'mediumWaterFactor',
    label: 'Median Water Factor',
    suffix: 'kgCO2e/kg',
    prefix: 'it'
  },
  {
    name: 'highWaterFactor',
    label: 'High Water Factor',
    suffix: 'kgCO2e/kg',
    prefix: 't'
  }
]

export const irrigationTypeForm = [
  {
    name: 'name',
    label: 'Name',
    rules: 'required'
  },
  {
    name: 'cpdIrrigationTypeId',
    label: 'CPD IrrigationType Id'
  },
  ...irrigationTypeFields,
  {
    name: 'notes',
    label: 'Notes'
  },
  {
    name: 'citations',
    label: 'Citations'
  }
]

export const elementTypeHasField = (element) => {
  return element.cpdVersion === 3 && element.type === 'hydrozone'
}
